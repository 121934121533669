<template  id="download-template">
    <transition name="download">
        <div class="download-mask">
            <div class="download-container d-flex flex-column h-75">
                <div class="flex-column align-content-around mt-1">
                    <h6 class="fw-bold">nuez v.0.3</h6>
                    <a href="/storage/nuez/nuez.x64.install.rar" download="nuez.x64.rar">
                        <button type="button" class="btn btn-outline-modest m-1">x64 installer</button>
                    </a>
                    <a href="/storage/nuez/nuez.x64.portable.rar" download="nuez.x64.rar">
                        <button type="button" class="btn btn-outline-modest m-1">x64  portable</button>
                    </a>
                </div>
            </div>
        </div>
    </transition>
</template>

<style scoped>
.btn-slim {
    padding:0;
    height: 1.5rem;
    background-color: rgba(255, 255, 255, 0.2);
}

.download-mask {
  position: fixed;
  z-index: 9998;
  top: 60px;
  right: 0;
  height: auto;
  background-color: rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
  border-radius: 5px;
  width: 200px;
}

.download-container {
  width: auto;
  margin: 0;
  padding: 5px 10px;
  color: rgb(153, 146, 141);
  background: rgba(163, 201, 160, 0.08);
  border: 1px solid rgba(156, 195, 155, 0.12);
  border-radius: 7px;
  box-shadow: 0 2px 8px rgba(75, 75, 75, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border-radius: 8px;
}

.download-container button {
    width: 100%;
    height: 35px;
    background-color: rgba(0,0,0,.4)

}

.download-enter { opacity: 0; }

.download-leave-active { opacity: 0; }

.download-enter .download-container,
.download-leave-active .download-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>