<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-container d-flex flex-column h-75">
                <embed src="https://www.buymeacoffee.com/widget/page/modestcoder" width="400px" height="500px"/>
                <div class="d-flex flex-row justify-content-between px-5 mt-2">
                    <p class="fw-bold my-auto">Thank you!</p>
                    <button class="btn btn-slim btn-primary w-25 my-auto" @click="$emit('close')">close</button>
                </div>
            </div>
        </div>
    </transition>
</template>

<style scoped>
.btn-slim {
    padding:0;
    height: 1.5rem;
    background-color: rgba(255, 255, 255, 0.2);
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 15%;
  margin-left: 40%;
  margin-right: 40%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.modal-container {
  width: auto;
  margin: 0;
  padding: 5px 10px;
  color: rgb(153, 146, 141);
  background: rgba(163, 201, 160, 0.08);
  border: 1px solid rgba(156, 195, 155, 0.12);
  border-radius: 7px;
  box-shadow: 0 2px 8px rgba(75, 75, 75, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border-radius: 8px;
}

.modal-enter { opacity: 0; }

.modal-leave-active { opacity: 0; }

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>