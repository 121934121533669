<template>
    <div class="modest-footer bg-darker" id="footer">
        <div class="d-flex flex-row mx-2">            
            <img :src="logo" :class="['rabbit', 'rounded-circle', 'p-1', 'my-auto', highlighted?'highlight-in':'', 'start']" width="24px" alt="logo" @click="BMAC=!BMAC"
            @mouseenter="highlighted=!highlighted" @mouseleave="highlighted=!highlighted" :style="highlighted?'highlight':''" />
            
            <img :src="kaffee" :class="['kaffee', BMAC?'':'invisible', 'mx-auto', 'mt-2', higher?'highlight-out':'']" width="150px" alt="buymeacoffee" 
            @click="callModal" @mouseenter="higher=!higher" @mouseleave="higher=!higher"/>
            
            <p class="mbr-fonts-style display-7">©2021 modestcoder</p>
        </div>
    </div>
</template>
<script>

import rabbit from '@/assets/resized/nrw85.png'
import coffee from '@/assets/resized/bmac.png'

export default({
 name:'Footer',
 props: [  ],
 emits: [ 'modalCall' ],
 data(){ return { BMAC:false, higher:false, highlighted:false, showModal:false, logo:rabbit, kaffee:coffee}},
 methods:{
     callModal(){
         this.$emit('modalCall')
         this.BMAC=false
     }
 }
})
</script>
<style scoped>
    #footer{
        display: block;
        position: fixed;
        bottom: 0;
        opacity: .05;
        transition: opacity .75s;
        height: 48px;
    }

    #footer:hover{
        opacity: 1;
        transition: opacity 1s; 
    }
    .rabbit {
        position: absolute;
        top: 5%;
        left: 1%;
        right: auto;
        height: 46px;
        width: 46px;
    }
    .kaffee{
        width: 150px;
        height: 1.6rem;
    }

    .highlight-in {
        box-shadow: inset 0 0 6px 6px rgba(255,255,255,.15)
    }

    .highlight-out {
        transform: scale(1.3);
        -moz-transform: scale(1.3);
        transition: transform .4s;
    }

    .invisible {
        opacity: 0;
    }
</style>