<template>

    <nav class="navbar navbar-dark navbar-expand-lg bg-darker fixed-top container-fluid">

        <a class="navbar-brand ms-5" href="#">{{ pagetitle }}</a>

        <button class="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#mobileNav" aria-controls="mobileNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse navcontent" id="mobileNav">
            <ul class="navbar-nav navbar-all-items">
                <li class="nav-item"><a class="nav-link" @click="$router.push('/')">home</a></li>

                <li v-for="(item, index) in navitems" :key="index" class="nav-item me-auto">
                    <a v-if="item.title" class="nav-link text-end" @click="$router.push('/'+item.route)" @mouseover="marked=true">{{ item.title }}</a>
                </li>

                <li v-if="pathcorrect" class="nav-item">
                    <a><i class=" p-2 iconfont fas fa-download nav-item mx-3" @click="callDowns" style="font-size:1.45rem"></i></a>
                </li>
                <li class="nav-item">
                    <a href="https://t.me/nuezclient" target="_blank"><i class=" p-2 iconfont fab fa-telegram-plane nav-item"></i></a>
                </li>
                <li class="nav-item">
                    <a href="mailto://me@modestcoders.com"><i class=" p-2 iconfont fas fa-at nav-item"></i></a>
                </li>
            </ul>
        </div>
    </nav>
</template>
<script>

export default({
    name: 'Navbar',
    data(){ return { marked:false, pathcorrect:true} },
    emits: [ 'downloadCall' ],
    props: [ 'pagetitle', 'navitems' ], 
    methods: { callDowns(){ this.$emit('downloadCall') } },
    mounted() { if((this.$route.path=='/older')||(this.$route.path=='/other')){this.pathcorrect=false}}
})
</script>
<style scoped>
    .iconfont { 
        font-size: 1.7rem;
    }

    .iconfont:hover { cursor: pointer; }
    
    #extra_icon {
        font-size: 1rem;
        margin-top: 7px;
        color: rgba(255,250,225,.4) 
    }

    .navbar .navbar-nav {
        margin-right: 3%;
        margin-left: auto;
        min-height: 3vh;
        max-height:auto;
    }

    .navbar .navbar-nav li:hover  {
        text-shadow: 2px 2px rgba(255,252,250,.2);
        filter: blur(.5px);
        -webkit-filter: blur(.5px);
    }

    .navbar .navbar-nav li:hover::after {
        position: absolute;
        bottom: 0;
        left:auto;
        right:5px;
        width: 25px;
        content: " ";
        border-bottom: 3px solid rgba(255,252,250,.2);
    }

    .navbar-all-items li {
        text-align: right;
        direction: rtl;
    }

</style>